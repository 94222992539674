<template >
    <div id="ticket">
    
        <div v-if="this.htmlContent !=''" class="content_ticket">
             <div class="group">
                  <img class="logob" src="@/assets/ticket/logo.svg" alt="">
             </div>
            <div class="brdr"></div>
             <div class="group">
               <div class="date">
                 <p class="txtup">Fecha de recepción <span> {{data.fecha}}</span></p>
                <p class="txtup">Hora de recepción <span>{{data.hr}}</span> </p>
               </div>
            <h3 class="txtup titulocli">{{data.nombre}}</h3>
            <div class="info1">
                   <div class="dflx">
                <p class="wd50 txtl txtup">Sexo</p><p class="wd50 txtr txtup">{{data.sexo}}</p>
            </div>
            <div class="dflx">
                 <p class="wd50 txtl txtup">Fecha de Nacimiento</p><p class="wd50 txtr txtup">{{ data.fecha_nacimiento !='undefined' && data.fecha_nacimiento  ?  data.fecha_nacimiento : '---'}}</p>
            </div>
            <div class="dflx">
                  <p class="wd50 txtl txtup">Edad</p><p class="wd50 txtr txtup">{{ data.edad !='undefined' &&  data.edad  ?  data.edad : '---'}}</p>
            </div>
            <div class="dflx">
                 <p class="wd50 txtl txtup">Facturar</p><p class="wd50 txtr txtup"> {{data.facturar}}</p>
            </div>
            <div class="dflx">
                    <p class="wd50 txtl txtup">Monto</p><p class="wd50 txtr txtup">{{data.monto}}</p>
            </div>
            <div class="dflx">
                <p class="wd50 txtl txtup">Método de pago</p><p class="wd50 txtr txtup"> {{data.metodo_pago}}</p>
            </div>
            <div class="dflx">
               <p class="wd50 txtl txtup">Nom. de cliente</p><p class="wd50 txtr txtup ">{{data.cliente.name}}</p>
            </div>
            </div>
             </div>
            <div class="brdr"></div>
            <!-- perfiles-->
        
            <div v-for="muestra in data.muestras" :key="muestra.id">
        
                <div v-for="perfil in muestra.perfiles" :key="perfil.id">
                 
                    <p class="perfil_name">{{perfil.name}}</p>
                    
                    <!--<p class="parametros">{{concat(perfil.resultados)}}</p>-->
                </div>
            </div>

            <div class="brdr"></div>
                <div class="group gpqr">
                     <p class="ptitle">CÓDIGO</p>
                <p class="pcode">{{data.code}}</p>

                 <div class="qrcode" v-html="this.htmlContent"></div>
            </div>
               
          <div class="brdr"></div>
         <div class="group footer">
              <img  src="@/assets/ticket/certificado2.png" alt="">
              <p>Sistema de Gestión de Calidad</p>
              <p class="CertISO">Certificado ISO 9001:2015</p>
              <p class="mb">No. de Certificado C2021-04405</p>
          <p>Tels. (662) 213 23 02, (662) 313 06 50</p>
          <p>barragan@laason.mx</p>
          <p>laason.mx</p>
         </div>
           <button class="oculto-impresion" @click="imprimir()">IMPRIMIR</button>
          
        </div>
        






      
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import qrcode from "qrcode"
export default {
    data(){
        return{
             htmlContent:''   
        }
    },
   async created(){
      if(!this.$route.query.id || !this.$route.query.code){
         this.$router.push("/administrador").catch(err => {});
      }else{
        let payload = {
            id: this.$route.query.id,
            option:'servicios_b'
        }
        await this.getInfoByIdTkt(payload)
        await  this.createQR()
       
      }
       
    },
    computed:{
          data(){
             return this.$store.getters["ticket/data"]
        },
    },
    methods:{
        ...mapActions('ticket',["getInfoByIdTkt"]),
        createQR: async function() {
            const id =this.$route.query.id 
            const code = this.$route.query.code
            //https://laason.mx
            //http://localhost:8080
            let url = `https://laason.mx/solicitud?id=${id}`
            const QR =  await qrcode.toDataURL(url); //display: flex;
            this.htmlContent = `
                
                 <img src="${QR}" alt="QR code">
              
            `;

   
           return this.htmlContent
               
         },
         imprimir: function  () {
            window.print();
         },
         concat(resultados){
            let str =''
            for (var i=0; i<resultados.length; i++) {
                 if(i < (resultados.length-1)){
                   str += resultados[i].pname +', '
                 }else{
                   str += resultados[i].pname  
                 }
            }

            return str
         }
    }
}
</script>
<style scoped  >
    
   #ticket{
    width: 322px;
    display:grid;
    margin-top: 30px;
    margin-left: 29px;
    color: rgba(0, 0, 0, 1);
    font-family: Arial, Helvetica, sans-serif;
   }
   .content_ticket{
   width:   322px;
   }
   .content_ticket p , h3{
    margin: 0vw;
   }
   .group{
     width: 322px;
     margin: auto;
       text-align: center;  
   }
   .txtup{
    text-transform: uppercase;
   }
      .wd50{
        width: 50%;
    }
    p.CertISO {
      font-weight: 700 !important;
      font-size: 11px !important;
    }
   p.txtl{
    text-align: left;
    font-size: 10px;
   }
   .brdr{
    border-bottom:1px solid rgba(0, 0, 0, 1);
    height: 1px;
   }
   p.txtr{
    text-align: right;
    font-size: 10px;
   }

   img.logob{
     width: 180px;
     height: 93px;
     margin-bottom: 5px
   }
   .date{
      margin-top: 6px;
      margin-bottom: 9px;
   }
   .date p{
    text-align: left;
  
     font-size: 10px;
     font-weight: 400;
   }

   h3.titulocli{
    font-size: 11px;
    font-weight: 700;
    text-align: center;
    width: 61%;
    margin-left: 25%;
          margin-bottom: 6px;
   }
   .date p span{
     
     font-weight: 700;
   }
   .info1{
    margin-bottom: 9px;
   }
    p.ptitle{
      height: 15px;
    width: 322px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 11px;
    }

      p.pcode{
       
        margin-bottom: 15px;
        font-size: 27px;
        font-weight: 700;
       
        text-align: center;


      }

      .qrcode{
        display: flex;
        justify-content: center; 
        align-items: center;
        margin-bottom: 7px;
      }
.footer{
  display: grid;
  justify-content: center; 
  align-items: center;
  margin-bottom: 31px;
}
.footer img{

  height: 80px;
  width: 142px;
  margin: auto;
  margin-top: 13px;
}
.mb{
  margin-bottom: 20px !important;
}

.footer p{
  font-family: Arial;
font-size: 10px;
font-weight: 400;

text-align: center;

}


.oculto-impresion{
  background: rgba(54, 57, 143, 1);
  border: none;
  color: white;
  text-transform: uppercase;
  font-family: Source Sans Pro;
  width: 100%;
  height: 3vw;
  font-size: 1vw;
  font-weight: 700;


}
p.perfil_name{
  font-family: Arial;
  font-size: 11px;
  font-weight: 700;
  margin-top: 9px;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: center;

}
p.parametros{
font-family: Arial;
font-size: 9px;
font-weight: 400;

text-align: left;
margin-bottom: 11px;
}


    @media print {
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
    }
   }
 
</style>